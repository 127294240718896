import { useEffect } from "react";
import * as reactQuery from "react-query";
import { useAuthentication } from "./useAuthentication";

const useQuery = (...args) => {
  const authentication = useAuthentication();
  const query = reactQuery.useQuery(...args);

  useEffect(() => {
    let id = setTimeout(() => {
      if (query.isError && query.error.response?.status === 401) {
        authentication.signOut();
      }
    }, 100);

    return () => clearTimeout(id);
  }, [authentication, query]);

  return query;
};

export default useQuery;
