import { CancelToken } from "axios";
import api from "../api/api";
import { reshapeAccount } from "../data/reshapeAccounts";
import useQuery from "./useQuery";

const fetchData = (url, options) => {
  const source = CancelToken.source();

  const promise = api.fetchData(url, {
    ...options,
    cancelToken: source.token,
  });

  promise.cancel = () => source.cancel("Query was canceled by React Query");

  return promise;
};

export const useFetchAccount = (accountId) => {
  const query = useQuery(["accounts", accountId], () =>
    fetchData(`/accounts/${accountId}`)
  );

  return {
    ...query,
    account: query.data && reshapeAccount(query.data.data),
  };
};

export const useFetchStatus = (applicationId) => {
  const query = useQuery("status", () =>
    applicationId ? fetchData("/status", { params: { sv: applicationId } }) : {}
  );

  return {
    ...query,
    status: query.data?.data,
  };
};

export const useFetchTwoFactorAuthenticationToken = (
  twoFactorAuthenticationEnabled
) => {
  const query = useQuery(["accounts", "twofactor-initiate"], () =>
    !twoFactorAuthenticationEnabled
      ? api.postData(`/accounts/twofactor-initiate`, {
          token: "",
        })
      : Promise.resolve()
  );

  return {
    ...query,
    url: query.data?.data.twoFactorSecretUrl,
    key: query.data?.data.twoFactorSecretKey,
  };
};
