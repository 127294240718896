import { useTheme } from "@material-ui/core";
import Banner from "../Common/Banner";

const ApplicationBanners = (props) => {
  const theme = useTheme();

  return (
    <>
      {props.splashMessage && (
        <Banner
          message={props.splashMessage}
          color={theme.palette.warning.light}
        />
      )}

      {props.children}

      {props.footerMessage && (
        <Banner
          message={props.footerMessage}
          color={theme.palette.info.light}
        />
      )}
    </>
  );
};

export default ApplicationBanners;
