import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";

const FeedbackDialog = (props) => {
  const [feedback, setFeedback] = useState();

  const checkValidity = () => !!feedback;

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Feedback</DialogTitle>
      <DialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        <Typography variant="body1">
          Provide any feedback you have regarding this application.
        </Typography>

        <TextField
          multiline
          variant="outlined"
          rows={10}
          value={feedback}
          required
          fullWidth
          onChange={(e) => {
            setFeedback(e.target.value);
          }}
        />
      </DialogForm>
      <DialogActions>
        <Button
          color="primary"
          disabled={props.saving}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          disabled={!checkValidity()}
          loading={props.saving}
          onClick={() => {
            props.onSave?.(feedback);
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

FeedbackDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  saving: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default FeedbackDialog;
