import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  TextField,
} from "@material-ui/core";
import { useQueryClient } from "react-query";
import { putData } from "../../api/api";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";
import useMutation from "../../hooks/useMutation";

export const EditAccountDetailsDialogPresenter = (props) => {
  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);
  const [organization, setOrganization] = useState(props.organization);

  const checkValidity = () => firstName && lastName;

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Account details</DialogTitle>
      <DialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        <TextField
          label="First name"
          value={firstName ?? ""}
          required
          fullWidth
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
        <TextField
          label="Last name"
          value={lastName ?? ""}
          required
          fullWidth
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
        <TextField
          label="Organization"
          value={organization ?? ""}
          fullWidth
          onChange={(e) => {
            setOrganization(e.target.value);
          }}
        />
      </DialogForm>
      <DialogActions>
        <Button
          color="primary"
          disabled={props.saving}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          disabled={!checkValidity()}
          loading={props.saving}
          onClick={() => {
            props.onSave?.({
              firstName,
              lastName,
              organization,
            });
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

EditAccountDetailsDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  organization: PropTypes.string,
  saving: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditAccountDetailsDialogPresenter.defaultProps = {
  saving: false,
};

const EditAccountDetailsDialog = (props) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (resource) => putData(`/accounts/${props.accountId}`, resource),
    {
      onSuccess: () => {
        props.onClose?.();

        queryClient.invalidateQueries(["accounts", props.accountId]);
      },
    }
  );

  const handleSave = (details) => {
    mutation.mutate(details);
  };

  return (
    <EditAccountDetailsDialogPresenter
      {...props}
      saving={mutation.isLoading}
      error={mutation.error?.response.data.message}
      onSave={handleSave}
      onCancel={props.onClose}
    />
  );
};

EditAccountDetailsDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default EditAccountDetailsDialog;
