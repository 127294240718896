import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { postData } from "../../api/api";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";
import PrivateTextInput from "../Common/PrivateTextInput";
import { checkPasswordInvalidity, getQueryConfiguration } from "../../utils";

export const PasswordResetDialogPresenter = (props) => {
  const [newPassword, setNewPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [token, setToken] = useState(props.token);

  const checkValidity = () =>
    !checkPasswordInvalidity(newPassword) &&
    newPassword === passwordConfirmation &&
    token;

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Reset password</DialogTitle>
      <DialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        <PrivateTextInput
          label="New password"
          value={newPassword ?? ""}
          required
          fullWidth
          helperText={
            checkPasswordInvalidity(newPassword) ?? "Password is valid"
          }
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
        <PrivateTextInput
          label="Confirm password"
          value={passwordConfirmation ?? ""}
          required
          fullWidth
          helperText={
            newPassword && newPassword === passwordConfirmation
              ? "Password confirmed"
              : "Password not confirmed"
          }
          onChange={(e) => {
            setPasswordConfirmation(e.target.value);
          }}
        />
        <TextField
          label="Token"
          value={token ?? ""}
          required
          fullWidth
          helperText="Password reset token sent via email"
          onChange={(e) => {
            setToken(e.target.value);
          }}
        />
      </DialogForm>
      <DialogActions>
        <Button
          color="primary"
          disabled={props.saving}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          disabled={!checkValidity()}
          loading={props.saving}
          onClick={() => {
            props.onSave?.({
              newPassword,
              token,
            });
          }}
        >
          Reset password
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

PasswordResetDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  token: PropTypes.string,
  saving: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

PasswordResetDialogPresenter.defaultProps = {
  saving: false,
};

const PasswordResetDialog = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation(
    (resource) => postData("/accounts/reset-password", resource),
    {
      onSuccess: () => {
        props.onClose?.();

        enqueueSnackbar("Password has been reset", {
          variant: "info",
        });
      },
    }
  );

  const { applicationId, token } = getQueryConfiguration();

  const handleSave = ({ newPassword, token }) => {
    mutation.mutate({
      applicationId,
      password: newPassword,
      confirmPassword: newPassword,
      token,
    });
  };

  return (
    <PasswordResetDialogPresenter
      {...props}
      token={token}
      saving={mutation.isLoading}
      error={mutation.error?.response.data.message}
      onSave={handleSave}
      onCancel={props.onClose}
    />
  );
};

PasswordResetDialog.propTypes = {
  onClose: PropTypes.func,
};

export default PasswordResetDialog;
