import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  deleteQueryConfigurationParameters,
  getQueryConfiguration,
} from "../../utils";
import ActivateAccountDialog from "./ActivateAccountDialog";
import LoginDialog from "./LoginDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import PasswordResetDialog from "./PasswordResetDialog";
import RegistrationDialog from "./RegistrationDialog";

const clearTemporaryUrlParameters = () => {
  deleteQueryConfigurationParameters(["activate", "setpwd", "guid"]);
};

const AuthenticationDialog = (props) => {
  const [mode, setMode] = useState(props.mode);

  useEffect(() => {
    const { activateAccount, resetPassword } = getQueryConfiguration();

    if (activateAccount) {
      setMode("activating");

      return;
    }

    if (resetPassword) {
      setMode("resetting password");

      return;
    }
  }, [setMode]);

  const handleCancel = () => {
    props.onCancel?.();
  };

  switch (mode) {
    case "activating": {
      return (
        <ActivateAccountDialog
          open={props.open}
          onClose={() => {
            clearTemporaryUrlParameters();
          }}
        />
      );
    }

    case "registering": {
      return (
        <RegistrationDialog
          open={props.open}
          onRegistration={props.onRegistration}
          onSignIn={() => {
            setMode("signing in");
          }}
        />
      );
    }

    case "requesting password reset": {
      return (
        <ForgotPasswordDialog
          open={props.open}
          onCancel={() => {
            setMode("signing in");
          }}
        />
      );
    }

    case "resetting password": {
      return (
        <PasswordResetDialog
          open={props.open}
          onClose={() => {
            clearTemporaryUrlParameters();
            setMode("signing in");
          }}
        />
      );
    }

    default: {
      return (
        <LoginDialog
          open={props.open}
          onSignIn={props.onSignIn}
          onPasswordReset={() => {
            setMode("requesting password reset");
          }}
          onRegistration={() => {
            setMode("registering");
          }}
          onCancel={handleCancel}
        />
      );
    }
  }
};

AuthenticationDialog.propTypes = {
  mode: PropTypes.oneOf([
    "activating",
    "registering",
    "requesting password reset",
    "resetting password",
    "signing in",
  ]),
};

AuthenticationDialog.defaultProps = {
  mode: "signing in",
};

export default AuthenticationDialog;
