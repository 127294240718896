import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useState } from "react";
import { useMutation } from "react-query";
import { postData } from "../../api/api";
import { DEFAULT_APPLICATION_TITLE } from "../../constants";
import { useApplicationDetails } from "../../hooks/useApplicationDetails";
import { checkPasswordInvalidity, getQueryConfiguration } from "../../utils";
import Banner from "../Common/Banner";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";
import PrivateTextInput from "../Common/PrivateTextInput";

const parseCountries = (text) =>
  text ? text.split(/\r?\n/).filter((country) => country) : [];

const StyledDialogForm = styled(DialogForm)(({ theme }) => ({
  "& .MuiFormControl-root.requirement": {
    margin: theme.spacing(2, 0),
  },
}));

const StyledLeftDialogActionsWrapper = styled("div")({
  flex: "1 0 0",
});

export const RegistrationDialogPresenter = (props) => {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [countries, setCountries] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();

  const theme = useTheme();

  const checkValidity = () =>
    firstName &&
    lastName &&
    email &&
    email.includes("@") &&
    !checkPasswordInvalidity(password) &&
    password === passwordConfirmation;

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <StyledDialogForm>
        {props.splashMessage && (
          <Banner
            message={props.splashMessage}
            color={theme.palette.warning.light}
          />
        )}

        {props.message && (
          <FormControl>
            <Typography variant="body1">{props.message}</Typography>
          </FormControl>
        )}

        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        {!props.completed && (
          <>
            <TextField
              label="First name"
              value={firstName ?? ""}
              required
              fullWidth
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <TextField
              label="Last name"
              value={lastName ?? ""}
              required
              fullWidth
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <TextField
              label="Email"
              value={email ?? ""}
              required
              fullWidth
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {props.requirement && (
              <TextField
                multiline
                variant="outlined"
                rows={10}
                label="Requirement"
                value={countries ?? ""}
                required
                fullWidth
                helperText={props.requirement}
                className="requirement"
                onChange={(e) => {
                  setCountries(e.target.value);
                }}
              />
            )}
            <PrivateTextInput
              label="New password"
              value={password ?? ""}
              required
              fullWidth
              helperText={
                checkPasswordInvalidity(password) ?? "Password is valid"
              }
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <PrivateTextInput
              label="Confirm password"
              value={passwordConfirmation ?? ""}
              required
              fullWidth
              helperText={
                password && password === passwordConfirmation
                  ? "Password confirmed"
                  : "Password not confirmed"
              }
              onChange={(e) => {
                setPasswordConfirmation(e.target.value);
              }}
            />
          </>
        )}

        {props.footerMessage && (
          <Banner
            message={props.footerMessage}
            color={theme.palette.info.light}
          />
        )}
      </StyledDialogForm>
      {!props.completed && (
        <DialogActions>
          <StyledLeftDialogActionsWrapper>
            <Button color="default" onClick={props.onSignIn}>
              Back
            </Button>
          </StyledLeftDialogActionsWrapper>
          <LoadingButton
            color="primary"
            disabled={!checkValidity()}
            loading={props.saving}
            onClick={() => {
              props.onSave?.({
                applicationId: props.applicationId,
                firstName,
                lastName,
                email,
                countries: parseCountries(countries),
                password,
              });
            }}
          >
            Create account
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

RegistrationDialogPresenter.defaultProps = {
  title: DEFAULT_APPLICATION_TITLE,
};

RegistrationDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  splashMessage: PropTypes.string,
  footerMessage: PropTypes.string,
  requirement: PropTypes.string,
  saving: PropTypes.bool,
  completed: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func,
  onSignIn: PropTypes.func,
};

RegistrationDialogPresenter.defaultProps = {
  saving: false,
  completed: false,
};

const RegistrationDialog = (props) => {
  const [completed, setCompleted] = useState(false);
  const [message, setMessage] = useState();

  const { customization } = useApplicationDetails();

  const mutation = useMutation(
    (resource) => postData(`/accounts/register`, resource),
    {
      onMutate: () => {
        setMessage();
      },
      onSuccess: () => {
        props.onClose?.();

        setCompleted(true);

        setMessage("Account validation email has been sent.");
      },
    }
  );

  const { applicationId } = getQueryConfiguration();

  const handleSave = (details) => {
    mutation.mutate({
      ...details,
      applicationId,
    });
  };

  return (
    <RegistrationDialogPresenter
      {...props}
      title="Account registration"
      applicationId={applicationId}
      requirement={customization?.requirement}
      saving={mutation.isLoading}
      completed={completed}
      message={message}
      error={mutation.error?.response.data.message}
      onSave={handleSave}
      onCancel={props.onClose}
    />
  );
};

export default RegistrationDialog;
