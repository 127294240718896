import Configuration from "./Configuration";
import { getQueryConfiguration } from "../utils";
import Main from "./Main";
import NoApplication from "./Dashboard/NoApplication";

const App = () => {
  const { applicationId } = getQueryConfiguration();

  return (
    <Configuration>
      {applicationId ? <Main /> : <NoApplication />}
    </Configuration>
  );
};

export default App;
