import axios from "axios";

const baseUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVICE_URL}/`;

const service = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

export const fetchData = (url, options) => service.get(url, options);

export const postData = (url, data, options) =>
  service.post(url, data, options);

export const putData = (url, data, options) => service.put(url, data, options);

export const signIn = (applicationId, email, password, authenticationToken) =>
  service.post("/accounts/authenticate", {
    applicationId,
    email,
    password,
    token: authenticationToken,
  });

export const signOut = () => service.post("/accounts/revoke-token");

const api = {
  fetchData,
  postData,
  putData,
  signIn,
  signOut,
};

export default api;
