import { styled } from "@material-ui/styles";
import { useAuthentication } from "../../hooks/useAuthentication";
import Application from "./Application";
import AccountDetails from "./AccountDetails";
import Security from "./Security";

const StyledComponent = styled("div")(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "row",
  gridRowGap: `${theme.spacing(2)}px`,
  margin: theme.spacing(0, 2),
}));

const Dashboard = () => {
  const { user } = useAuthentication();

  return (
    <StyledComponent>
      <Application />

      {user.accountId && (
        <>
          <AccountDetails accountId={user.accountId} />
          <Security
            accountId={user.accountId}
            twoFactorAuthenticationEnabled={user.twoFactorAuthenticationEnabled}
          />
        </>
      )}
    </StyledComponent>
  );
};

export default Dashboard;
