import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { useApplicationDetails } from "../../hooks/useApplicationDetails";
import { useFetchAccount } from "../../hooks/useQueries";
import ChangePasswordDialog from "./ChangePasswordDialog";
import TwoFactorAuthenticationDialog from "./TwoFactorAuthenticationDialog";

const Security = (props) => {
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(
    false
  );
  const [
    twoFactorAuthenticationDialogOpen,
    setTwoFactorAuthenticationDialogOpen,
  ] = useState(false);

  const { twoFactorAuthenticationAvailable } = useApplicationDetails();

  const { account } = useFetchAccount(props.accountId);

  const StyledButtonGroup = styled("div")(({ theme }) => ({
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: `${theme.spacing(1)}px`,
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    marginTop: `${theme.spacing(1)}px`,
    width: "fit-content",
  }));

  return (
    <div>
      <Typography variant="h6">Security</Typography>

      {twoFactorAuthenticationAvailable ? (
        account?.twoFactorAuthenticationEnabled ? (
          <>
            <p>
              Congratulations. Two-factor authentication (2FA) is enabled on
              this account.
            </p>
          </>
        ) : (
          <p>
            Two-factor authentication (2FA) has <em>not</em> been enabled on
            this account. We strongly recommend you enable it to protect your
            data.
          </p>
        )
      ) : null}

      <StyledButtonGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setChangePasswordDialogOpen(true);
          }}
        >
          Change password...
        </Button>
        {twoFactorAuthenticationAvailable
          ? account && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setTwoFactorAuthenticationDialogOpen(true);
                }}
              >
                {`${
                  !account.twoFactorAuthenticationEnabled ? "Enable" : "Disable"
                } 2FA...`}
              </Button>
            )
          : null}
      </StyledButtonGroup>

      {changePasswordDialogOpen && (
        <ChangePasswordDialog
          open={true}
          accountId={props.accountId}
          onClose={() => {
            setChangePasswordDialogOpen(false);
          }}
        />
      )}

      {twoFactorAuthenticationDialogOpen && (
        <TwoFactorAuthenticationDialog
          open={true}
          accountId={props.accountId}
          twoFactorAuthenticationEnabled={props.twoFactorAuthenticationEnabled}
          onClose={() => {
            setTwoFactorAuthenticationDialogOpen(false);
          }}
        />
      )}
    </div>
  );
};

Security.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default Security;
