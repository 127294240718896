import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import { styled } from "@material-ui/styles";

const StyledComponent = styled("div")({
  alignItems: "center",
  display: "flex",
});

const StyledErrorIcon = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  marginRight: `${theme.spacing(1)}px`,
}));

const StyledMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const Error = ({ message }) => (
  <StyledComponent>
    <StyledErrorIcon />
    <StyledMessage variant="body1">{message}</StyledMessage>
  </StyledComponent>
);

Error.propTypes = {
  message: PropTypes.string,
};

Error.defaultProps = {
  message: "Oops! Something went wrong. Try refreshing the page.",
};

export default Error;
