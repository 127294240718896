import PropTypes from "prop-types";
import { styled } from "@material-ui/styles";

const StyledImage = styled("img")({
  height: "auto",
  maxHeight: "64px",
  width: "auto",
});

const ApplicationLogo = (props) => (
  <a href={props.url}>
    <StyledImage src={props.imageUrl} alt="application logo" />
  </a>
);

ApplicationLogo.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  url: PropTypes.string,
};

ApplicationLogo.defaultProps = {
  url: "https://avenirhealth.org",
};

export default ApplicationLogo;
