import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import useLocalStorage from "../hooks/useLocalStorage";
import { getQueryConfiguration } from "../utils";

const USER_LOCAL_STORAGE_KEY = "authenticatedUser";

const authenticationContext = createContext();

const useProvideAuthentication = () => {
  const [error, setError] = useState();
  const [
    authenticationTokenSecretUrl,
    setAuthenticationTokenSecretUrl,
  ] = useState();
  const [
    authenticationTokenSecretKey,
    setAuthenticationTokenSecretKey,
  ] = useState();

  const [user, setUser] = useLocalStorage(USER_LOCAL_STORAGE_KEY);

  const navigate = useNavigate();

  const signIn = async (email, password, authenticationToken) => {
    try {
      setError(undefined);
      setAuthenticationTokenSecretUrl(undefined);
      setAuthenticationTokenSecretKey(undefined);

      const { applicationId } = getQueryConfiguration();

      const response = await api.signIn(
        applicationId,
        email,
        password,
        authenticationToken
      );
      const data = response.data;

      setUser({
        accountId: data.accountId,
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        twoFactorAuthenticationEnabled: data.isTwoFactorEnabled,
      });
    } catch (error) {
      setUser(undefined);
      setError(error.response?.data?.message || error.message);

      if (error.response?.status === 401) {
        setAuthenticationTokenSecretUrl(
          error.response?.data?.twoFactorSecretUrl
        );
        setAuthenticationTokenSecretKey(
          error.response?.data?.twoFactorSecretKey
        );
      }
    }
  };

  const signOut = () => {
    api.signOut();
    setUser(undefined);

    navigate("/");
  };

  return {
    user,
    error,
    authenticationTokenSecretUrl,
    authenticationTokenSecretKey,
    signIn,
    signOut,
  };
};

export const ProvideAuthentication = ({ children }) => {
  const authentication = useProvideAuthentication();

  return (
    <authenticationContext.Provider value={authentication}>
      {children}
    </authenticationContext.Provider>
  );
};

export const useAuthentication = () => {
  return useContext(authenticationContext);
};
