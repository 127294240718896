import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
} from "@material-ui/core";
import { putData } from "../../api/api";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";
import PrivateTextInput from "../Common/PrivateTextInput";
import useMutation from "../../hooks/useMutation";
import { checkPasswordInvalidity } from "../../utils";

export const ChangePasswordDialogPresenter = (props) => {
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();

  const checkValidity = () =>
    currentPassword &&
    !checkPasswordInvalidity(newPassword) &&
    newPassword === passwordConfirmation;

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Change password</DialogTitle>
      <DialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        <PrivateTextInput
          label="Current password"
          value={currentPassword ?? ""}
          required
          fullWidth
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
        />
        <PrivateTextInput
          label="New password"
          value={newPassword ?? ""}
          required
          fullWidth
          helperText={
            checkPasswordInvalidity(newPassword) ?? "Password is valid"
          }
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
        <PrivateTextInput
          label="Confirm password"
          value={passwordConfirmation ?? ""}
          required
          fullWidth
          helperText={
            newPassword && newPassword === passwordConfirmation
              ? "Password confirmed"
              : "Password not confirmed"
          }
          onChange={(e) => {
            setPasswordConfirmation(e.target.value);
          }}
        />
      </DialogForm>
      <DialogActions>
        <Button
          color="primary"
          disabled={props.saving}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          disabled={!checkValidity()}
          loading={props.saving}
          onClick={() => {
            props.onSave?.({
              currentPassword,
              newPassword,
            });
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ChangePasswordDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  saving: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

const ChangePasswordDialog = (props) => {
  const mutation = useMutation(
    (resource) => putData(`/accounts/change-password`, resource),
    {
      onSuccess: props.onClose,
    }
  );

  const handleSave = ({ currentPassword, newPassword }) => {
    mutation.mutate({
      currentPassword,
      newPassword,
    });
  };

  return (
    <ChangePasswordDialogPresenter
      {...props}
      saving={mutation.isLoading}
      error={mutation.error?.response.data.message}
      onSave={handleSave}
      onCancel={props.onClose}
    />
  );
};

ChangePasswordDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default ChangePasswordDialog;
