import { styled } from "@material-ui/styles";
import { ReactComponent as AvenirHealthLogo } from "../../images/logo.svg";
import ApplicationLogo from "./ApplicationLogo";

const StyledComponent = styled("div")(({ theme }) => ({
  marginRight: `${theme.spacing(2)}px`,
}));

const Logo = (props) => (
  <StyledComponent>
    {!props.imageUrl ? (
      props.defaultToAvenirHealth ? (
        <AvenirHealthLogo width={110} height={44} />
      ) : null
    ) : (
      <ApplicationLogo imageUrl={props.imageUrl} url={props.url} />
    )}
  </StyledComponent>
);

export default Logo;
