import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import { useMutation } from "react-query";
import { postData } from "../../api/api";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";
import { getQueryConfiguration } from "../../utils";

export const ForgotPasswordDialogPresenter = (props) => {
  const [email, setEmail] = useState();

  const checkValidity = () => email && email.includes("@");

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Password reset request</DialogTitle>
      <DialogForm>
        {props.message && (
          <FormControl>
            <Typography variant="body1">{props.message}</Typography>
          </FormControl>
        )}

        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        <TextField
          type="email"
          label="Email"
          value={email ?? ""}
          required
          fullWidth
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </DialogForm>
      <DialogActions>
        <Button
          color="primary"
          disabled={props.saving}
          onClick={props.onCancel}
        >
          Back
        </Button>
        <LoadingButton
          color="primary"
          disabled={!checkValidity()}
          loading={props.saving}
          onClick={() => {
            props.onSave?.(email);
          }}
        >
          Send reset instructions
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ForgotPasswordDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  saving: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

ForgotPasswordDialogPresenter.defaultProps = {
  saving: false,
};

const ForgotPasswordDialog = (props) => {
  const [message, setMessage] = useState();

  const mutation = useMutation(
    (resource) => postData("/accounts/forgot-password", resource),
    {
      onMutate: () => {
        setMessage();
      },
      onSuccess: () => {
        props.onClose?.();

        setMessage("Security email has been sent.");
      },
    }
  );

  const { applicationId } = getQueryConfiguration();

  const handleSave = (email) => {
    mutation.mutate({
      email,
      applicationId,
    });
  };

  return (
    <ForgotPasswordDialogPresenter
      {...props}
      saving={mutation.isLoading}
      message={message}
      error={mutation.error?.response.data.message}
      onSave={handleSave}
    />
  );
};

ForgotPasswordDialog.propTypes = {
  onClose: PropTypes.func,
};

export default ForgotPasswordDialog;
