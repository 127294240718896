import { styled } from "@material-ui/styles";
import NavBar from "../NavBar/NavBar";

const StyledMessage = styled("p")(({ theme }) => ({
  margin: theme.spacing(2, 1, 1),
}));

const NoApplication = () => (
  <>
    <NavBar />

    <StyledMessage>
      Thank you for visiting our account manager. Please visit via the service
      you are using so that we can support you properly.
    </StyledMessage>
  </>
);

export default NoApplication;
