import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { postData } from "../../api/api";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";
import { getQueryConfiguration } from "../../utils";

const ApplicationDetails = (props) => (
  <div>
    <Typography variant="body1">
      Your account has been verified. You can now sign in to{" "}
      <a href={props.url}>{props.humanName}</a>.
    </Typography>
  </div>
);

export const ActivateAccountDialogPresenter = (props) => {
  const [verificationCode, setVerificationCode] = useState(
    props.verificationCode
  );

  const checkValidity = () => !!verificationCode;

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Activate account</DialogTitle>
      <DialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        {!props.completed ? (
          <TextField
            label="Code"
            value={verificationCode ?? ""}
            required
            fullWidth
            helperText="Verifcation code sent via email"
            onChange={(e) => {
              setVerificationCode(e.target.value);
            }}
          />
        ) : (
          <ApplicationDetails
            humanName={props.applicationHumanName}
            url={props.applicationUrl}
          />
        )}
      </DialogForm>
      {!props.completed && (
        <DialogActions>
          <Button
            color="primary"
            disabled={props.saving}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            disabled={!checkValidity()}
            loading={props.saving}
            onClick={() => {
              props.onSave?.(verificationCode);
            }}
          >
            Verify
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

ActivateAccountDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  saving: PropTypes.bool,
  completed: PropTypes.bool,
  applicationHumanName: PropTypes.string,
  applicationUrl: PropTypes.string,
  error: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

ActivateAccountDialogPresenter.defaultProps = {
  saving: false,
  completed: false,
};

const ActivateAccountDialog = (props) => {
  const [completed, setCompleted] = useState(false);
  const [applicationHumanName, setApplicationHumanName] = useState();
  const [applicationUrl, setApplicationUrl] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation(
    (resource) => postData("/accounts/verify-email", resource),
    {
      onSuccess: (data) => {
        props.onVerify?.();

        setCompleted(true);
        setApplicationHumanName(data?.data.humanName);
        setApplicationUrl(data?.data.url);

        enqueueSnackbar("Account email has been verified", {
          variant: "info",
        });
      },
    }
  );

  const { applicationId, token } = getQueryConfiguration();

  const handleSave = (verificationCode) => {
    mutation.mutate({
      applicationId,
      token: verificationCode,
    });

    props.onClose?.();
  };

  return (
    <ActivateAccountDialogPresenter
      {...props}
      verificationCode={token}
      saving={mutation.isLoading}
      completed={completed}
      applicationHumanName={applicationHumanName}
      applicationUrl={applicationUrl}
      error={mutation.error?.response.data.message}
      onSave={handleSave}
      onCancel={props.onClose}
    />
  );
};

ActivateAccountDialog.propTypes = {
  verificationCode: PropTypes.string,
  onClose: PropTypes.func,
};

export default ActivateAccountDialog;
