import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import { DEFAULT_APPLICATION_TITLE } from "../../constants";
import { useApplicationDetails } from "../../hooks/useApplicationDetails";
import { useAuthentication } from "../../hooks/useAuthentication";
import PasswordAuthentication from "./PasswordAuthentication";
import TokenAuthentication from "./TokenAuthentication";

const StyledLeftDialogActionsWrapper = styled("div")({
  flex: "1 0 0",
});

export const LoginDialogPresenter = (props) => {
  const handleAuthenticationTokenChange = (authenticationToken) => {
    if (/^\d{0,8}$/.test(authenticationToken)) {
      props.onAuthenticationTokenChange(authenticationToken);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        <PasswordAuthentication
          email={props.email}
          password={props.password}
          onEmailChange={props.onEmailChange}
          onPasswordChange={props.onPasswordChange}
        />

        {props.twoFactorAuthenticationAvailable && (
          <TokenAuthentication
            authenticationToken={props.authenticationToken}
            authenticationTokenSecretUrl={props.authenticationTokenSecretUrl}
            authenticationTokenSecretKey={props.authenticationTokenSecretKey}
            onAuthenticationTokenChange={handleAuthenticationTokenChange}
          />
        )}
      </DialogForm>
      <DialogActions>
        <StyledLeftDialogActionsWrapper>
          <Button color="default" onClick={props.onRegistration}>
            Register
          </Button>
        </StyledLeftDialogActionsWrapper>
        <Button color="secondary" onClick={props.onPasswordReset}>
          Reset password
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props.onSignIn?.();
          }}
        >
          Sign in
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LoginDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  twoFactorAuthenticationAvailable: PropTypes.bool,
  authenticationTokenSecretUrl: PropTypes.string,
  authenticationTokenSecretKey: PropTypes.string,
  authenticationToken: PropTypes.string,
  error: PropTypes.string,
  onEmailChange: PropTypes.func,
  onPasswordChange: PropTypes.func,
  onAuthenticationTokenChange: PropTypes.func,
  onSignIn: PropTypes.func,
  onRegistration: PropTypes.func,
  onPasswordReset: PropTypes.func,
};

LoginDialogPresenter.defaultProps = {
  title: DEFAULT_APPLICATION_TITLE,
  twoFactorAuthenticationAvailable: false,
};

const LoginDialog = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [authenticationToken, setAuthenticationToken] = useState();

  const { title, twoFactorAuthenticationAvailable } = useApplicationDetails();

  const authentication = useAuthentication();

  const handleSignIn = () => {
    authentication.signIn(email, password, authenticationToken);
  };

  let error = authentication.error;

  if (error === "Email or password is incorrect") {
    error = "Your credentials were invalid. Please check them and try again.";
  }

  if (error === "Two factor authentication must be supplied") {
    error = "2FA is enabled. You must supply a token to sign in.";
  }

  return (
    <LoginDialogPresenter
      {...props}
      title={title}
      email={email}
      password={password}
      twoFactorAuthenticationAvailable={twoFactorAuthenticationAvailable}
      authenticationTokenSecretUrl={authentication.authenticationTokenSecretUrl}
      authenticationTokenSecretKey={authentication.authenticationTokenSecretKey}
      authenticationToken={authenticationToken}
      error={error}
      onEmailChange={setEmail}
      onPasswordChange={setPassword}
      onAuthenticationTokenChange={setAuthenticationToken}
      onSignIn={handleSignIn}
    />
  );
};

export default LoginDialog;
