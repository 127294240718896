import { createContext, useContext } from "react";
import { DEFAULT_APPLICATION_TITLE } from "../constants";
import { getQueryConfiguration } from "../utils";
import { useFetchStatus } from "./useQueries";

const applicationDetailsContext = createContext();

const useProvideApplicationDetails = () => {
  const { applicationId } = getQueryConfiguration();

  const { status, isLoading } = useFetchStatus(applicationId);
  const customization = status?.customisation;

  return {
    version: status?.systemVersion,
    title: status?.title ?? DEFAULT_APPLICATION_TITLE,
    splashMessage: status?.splashMessage,
    footerMessage: status?.footerMessage,
    proxiedApplicationName: status?.humanName,
    proxiedApplicationUrl: status?.url,
    twoFactorAuthenticationAvailable: !!status?.isTwoFactorAvailable,
    customization: {
      requirement: customization?.requirement,
      logoUrl: customization?.logoUrl,
      colors: {
        foreground: customization?.foreColor,
        background: customization?.backColor,
        link: customization?.linkColor,
      },
    },
    loaded: !isLoading,
  };
};

export const ProvideApplicationDetails = ({ children }) => {
  const applicationDetails = useProvideApplicationDetails();

  return (
    <applicationDetailsContext.Provider value={applicationDetails}>
      {children}
    </applicationDetailsContext.Provider>
  );
};

export const useApplicationDetails = () => {
  return useContext(applicationDetailsContext);
};
