import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { styled } from "@material-ui/styles";

const StyledComponent = styled(Typography)(({ theme, ...props }) => ({
  backgroundColor: props.backgroundcolor,
  margin: theme.spacing(2, 0),
  padding: theme.spacing(0.5, 0),
}));

const Banner = (props) => (
  <StyledComponent
    variant="body1"
    align="center"
    foobar={10}
    backgroundcolor={props.color}
  >
    {props.message}
  </StyledComponent>
);

Banner.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Banner.defaultProps = {
  color: "white",
};

export default Banner;
