import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { useQueryClient } from "react-query";
import { postData } from "../../api/api";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import Loading from "../Common/Loading";
import LoadingButton from "../Common/LoadingButton";
import QrCode from "../Common/QrCode";
import useMutation from "../../hooks/useMutation";
import { useFetchTwoFactorAuthenticationToken } from "../../hooks/useQueries";

const StyledQrCode = styled(QrCode)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const TwoFactorAuthenticationDialogPresenter = (props) => {
  const [token, setToken] = useState();

  const checkValidity = () => token;

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>2FA</DialogTitle>
      <DialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        {props.authenticationTokenSecretKey ? (
          <>
            <Typography variant="body1">
              Enabling two-factor authentication (2FA) can result in you being
              locked out of your account. Only enable it if you are confident
              you understand the implications.
            </Typography>
            <Typography variant="body1">
              If you <em>do not</em> wish to enable 2FA, cancel the dialog.
            </Typography>
          </>
        ) : (
          <Typography variant="body1">
            Two-factor authentication (2FA) protects your data. Disabling it is
            <em>not</em> recommended.
          </Typography>
        )}

        {props.loading && <Loading message="Fetching 2FA token" />}

        {props.authenticationTokenSecretKey && (
          <div>
            <StyledQrCode value={props.authenticationTokenSecretUrl} />
            <Typography variant="body1">
              {props.authenticationTokenSecretKey}
            </Typography>
          </div>
        )}

        <TextField
          label="Token"
          value={token ?? ""}
          required
          fullWidth
          helperText="Use your 2FA app (e.g. Microsoft Authenticator) to obtain a token"
          onChange={(e) => {
            setToken(e.target.value);
          }}
        />
      </DialogForm>
      <DialogActions>
        <Button
          color="primary"
          disabled={props.saving}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        {props.authenticationTokenSecretKey && (
          <LoadingButton
            color="primary"
            disabled={!checkValidity()}
            loading={props.saving}
            onClick={() => {
              props.onEnable?.(token);
            }}
          >
            Enable
          </LoadingButton>
        )}
        {!props.authenticationTokenSecretKey && (
          <LoadingButton
            color="primary"
            disabled={!checkValidity()}
            loading={props.saving}
            onClick={props.onDisable}
          >
            Disable
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

TwoFactorAuthenticationDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  authenticationTokenSecretUrl: PropTypes.string,
  authenticationTokenSecretKey: PropTypes.string,
  loading: PropTypes.bool,
  validating: PropTypes.bool,
  error: PropTypes.string,
  onEnable: PropTypes.func,
  onDisable: PropTypes.func,
  onCancel: PropTypes.func,
};

TwoFactorAuthenticationDialogPresenter.defaultProps = {
  loading: false,
  validating: false,
};

const TwoFactorAuthenticationDialog = (props) => {
  const [mutationError, setMutationError] = useState();

  const { isLoading, url, key, error } = useFetchTwoFactorAuthenticationToken(
    props.twoFactorAuthenticationEnabled
  );

  const queryClient = useQueryClient();

  const enableMutation = useMutation(
    (resource) => postData(`/accounts/twofactor-validate`, resource),
    {
      onMutate: () => {
        setMutationError();
      },
      onSuccess: (data) => {
        if (!data?.data.good) {
          setMutationError("Invalid 2FA token was supplied");

          return;
        }

        props.onClose?.();

        queryClient.invalidateQueries("accounts");
      },
      onError: (error) => {
        setMutationError(error.response.data.message);
      },
    }
  );

  const disableMutation = useMutation(
    () => postData(`/accounts/twofactor-disable`),
    {
      onMutate: () => {
        setMutationError();
      },
      onSuccess: () => {
        props.onClose?.();

        queryClient.invalidateQueries("accounts");
      },
      onError: (error) => {
        setMutationError(error.response.data.message);
      },
    }
  );

  const handleEnable = (token) => {
    enableMutation.mutate({
      token,
    });
  };

  const handleDisable = (token) => {
    disableMutation.mutate();
  };

  return (
    <TwoFactorAuthenticationDialogPresenter
      {...props}
      authenticationTokenSecretUrl={url}
      authenticationTokenSecretKey={key}
      loading={isLoading}
      enabling={enableMutation.isLoading}
      error={error ?? mutationError}
      onEnable={handleEnable}
      onDisable={handleDisable}
      onCancel={props.onClose}
    />
  );
};

TwoFactorAuthenticationDialog.propTypes = {
  twoFactorAuthenticationEnabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default TwoFactorAuthenticationDialog;
