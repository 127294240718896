import { AppBar, Button, IconButton, Toolbar } from "@material-ui/core";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useApplicationDetails } from "../../hooks/useApplicationDetails";
import { useAuthentication } from "../../hooks/useAuthentication";
import Logo from "./Logo";

const StyledAppBar = styled(({ backgroundColor, menuItemColor, ...rest }) => (
  <AppBar {...rest} />
))({
  backgroundColor: (props) => props.backgroundColor,
  display: "flex",
  "& .MuiButton-label": {
    color: (props) => props.menuItemColor,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
  },
});

const StyledMainMenu = styled("div")({
  flexGrow: 1,
});

export const NavBarPresenter = (props) => (
  <StyledAppBar
    position="static"
    backgroundColor={props.backgroundColor}
    menuItemColor={props.menuItemColor}
  >
    <Toolbar>
      <Logo
        imageUrl={props.imageUrl}
        url={props.url}
        defaultToAvenirHealth={props.defaultToAvenirHealth ?? true}
      />
      <StyledMainMenu />
      {props.signedIn && (
        <div>
          <Button color="inherit" onClick={props.onSignOut}>
            Sign out
          </Button>
        </div>
      )}
      {props.feedbackEnabled && (
        <IconButton color="inherit" onClick={props.onFeedback}>
          <FeedbackIcon />
        </IconButton>
      )}
    </Toolbar>
  </StyledAppBar>
);

NavBarPresenter.propTypes = {
  backgroundColor: PropTypes.string,
  menuItemColor: PropTypes.string,
  imageUrl: PropTypes.string,
  url: PropTypes.string,
  feedbackEnabled: PropTypes.bool,
  onFeedback: PropTypes.func,
};

NavBarPresenter.defaultProps = {
  feedbackEnabled: false,
};

const NavBar = (props) => {
  const authentication = useAuthentication();

  const applicationDetails = useApplicationDetails();

  console.log(applicationDetails);

  return (
    <NavBarPresenter
      {...props}
      signedIn={Boolean(authentication.user)}
      backgroundColor={applicationDetails.customization.colors.background}
      menuItemColor={applicationDetails.customization.colors.link}
      imageUrl={applicationDetails.customization.logoUrl}
      url={applicationDetails.proxiedApplicationUrl}
      defaultToAvenirHealth={applicationDetails.loaded}
      onSignOut={authentication.signOut}
    />
  );
};

export default NavBar;
