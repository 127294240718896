import { useEffect, useState } from "react";
import { styled } from "@material-ui/styles";
import { Navigate, Route, Routes } from "react-router-dom";
import { useApplicationDetails } from "../hooks/useApplicationDetails";
import { useAuthentication } from "../hooks/useAuthentication";
import { getQueryConfiguration } from "../utils";
import AuthenticationDialog from "./Authentication/AuthenticationDialog";
import ApplicationBanners from "./Common/ApplicationBanners";
import Dashboard from "./Dashboard/Dashboard";
import FeedbackDialog from "./NavBar/FeedbackDialog";
import NavBar from "./NavBar/NavBar";

const StyledContent = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 1, 1),
}));

const UnauthenticatedDialog = ({ mode }) => {
  const MODES = {
    fg: "requesting password reset",
    new: "registering",
  };

  return <AuthenticationDialog open={true} mode={MODES[mode]} />;
};

const Main = () => {
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  const { title, splashMessage, footerMessage } = useApplicationDetails();

  const authentication = useAuthentication();

  const { mode, forceAuthenticationMode } = getQueryConfiguration();

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleFeedbackProvided = (feedback) => {
    setFeedbackDialogOpen(false);
  };

  return (
    <>
      <NavBar
        onFeedback={() => {
          setFeedbackDialogOpen(true);
        }}
      />

      {authentication.user && !forceAuthenticationMode ? (
        <StyledContent>
          <ApplicationBanners
            splashMessage={splashMessage}
            footerMessage={footerMessage}
          >
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route element={<Navigate to="dashboard" />} />
            </Routes>
          </ApplicationBanners>
        </StyledContent>
      ) : (
        <UnauthenticatedDialog mode={mode} />
      )}
      {feedbackDialogOpen && (
        <FeedbackDialog
          open={feedbackDialogOpen}
          onSave={handleFeedbackProvided}
          onCancel={() => {
            setFeedbackDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Main;
