import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { HashRouter as Router } from "react-router-dom";
import { ProvideApplicationDetails } from "../hooks/useApplicationDetails";
import { ProvideAuthentication } from "../hooks/useAuthentication";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const theme = createMuiTheme();

const Configuration = ({ children }) => (
  <Router>
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <QueryClientProvider client={queryClient}>
          <ProvideApplicationDetails>
            <ProvideAuthentication>{children}</ProvideAuthentication>
          </ProvideApplicationDetails>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  </Router>
);

export default Configuration;
