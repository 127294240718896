import PropTypes from "prop-types";
import { Link, Typography } from "@material-ui/core";
import { useApplicationDetails } from "../../hooks/useApplicationDetails";

export const ApplicationPresenter = (props) => (
  <div>
    <Typography variant="h6">{props.name || "Application"}</Typography>

    {props.url && (
      <Typography variant="body1">
        Return to{" "}
        <Link href={props.url}>{props.name || "the application"}</Link> and sign
        in.
      </Typography>
    )}
  </div>
);

ApplicationPresenter.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
};

const Application = (props) => {
  const {
    proxiedApplicationName,
    proxiedApplicationUrl,
  } = useApplicationDetails();

  return (
    <ApplicationPresenter
      {...props}
      name={proxiedApplicationName}
      url={proxiedApplicationUrl}
    />
  );
};

export default Application;
