import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Error from "../Common/Error";
import { useFetchAccount } from "../../hooks/useQueries";
import EditAccountDetailsDialog from "./EditAccountDetailsDialog";

const StyledButtonGroup = styled("div")(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "column",
  gridColumnGap: `${theme.spacing(1)}px`,
  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  marginTop: `${theme.spacing(1)}px`,
  width: "fit-content",
}));

export const AccountDetailsPresenter = (props) =>
  !props.error ? (
    <div>
      <Typography variant="h6">Account details</Typography>
      <Typography variant="body1" component="div">
        {props.lastName && <div>{`${props.firstName} ${props.lastName}`}</div>}
        <div>{props.organization}</div>
        <div>{props.email}</div>
        <div>Creation date: {props.firstVisitTime.toLocaleString()}</div>
        <div>Last visit: {props.lastVisitTime.toLocaleString()}</div>
      </Typography>
      <StyledButtonGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={props.onEditDetails}
        >
          Edit details...
        </Button>
      </StyledButtonGroup>
    </div>
  ) : (
    <Error message="Failed to retrieve account details" />
  );

AccountDetailsPresenter.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  organization: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstVisitTime: PropTypes.instanceOf(Date).isRequired,
  lastVisitTime: PropTypes.instanceOf(Date).isRequired,
  onEditDetails: PropTypes.func,
};

const AccountDetails = ({ accountId, ...rest }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const { account, error } = useFetchAccount(accountId);

  return account ? (
    <>
      <AccountDetailsPresenter
        {...rest}
        {...account}
        error={error?.response?.data.message}
        onEditDetails={() => {
          setEditDialogOpen(true);
        }}
      />
      {editDialogOpen && (
        <EditAccountDetailsDialog
          {...account}
          open={true}
          onClose={() => {
            setEditDialogOpen(false);
          }}
        />
      )}
    </>
  ) : null;
};

AccountDetails.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default AccountDetails;
